.hor-app {
  width: 100%;
  position: relative;
}

.hor-app-back {
  width: 100%;
  position: absolute; 
  z-index: 1;
  object-fit: cover;
}

.hor-app-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
}

.hor-app-content-left {
  width: 50%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.hor-app-content-snapshot {
  width: 55%;
  margin-left: 2.5%;
}

.hor-app-content-snapshot-2 {
  width: 55%;
  margin-left: -15%;
}

.hor-app-content-right {
  width: 50%;
  /* background-color: white; */
  /* opacity: 0.2; */
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hor-copywrite {
  width: 100%;
  font-size: 1.5vmin;
  color: white;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.hor-head {
  margin-left: 3%;
  margin-top: 3%;
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
}

.hor-head-icon {
  width: 6vh;
  height: 6vh;
}

.hor-head-content {
  color: white;
  font-size: 4vh;
  margin-left: 1.5vh;
}

.hor-head-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.hor-head-contract {
  color: white;
  font-size: 3vh;
  margin-left: 1vh;
  text-decoration: none;
}

.hor-head-privacy {
  color: white;
  font-size: 3vh;
  margin-left: 5vh;
  text-decoration: none;
}

.hor-content-body {
  width: 84%;
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8%;
  margin-right: 8%;
}

.hor-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hor-product-icon {
  width: 6.5vh;
  height: 6.5vh;
  margin-right: 2vh;
}

.hor-product-name {
  color: white;
  font-size: 4vh;
}

.hor-product-desc {
  color: white;
  font-size: 1.6vh;
  font-weight: 400;
  line-height: 3.8vh;
  margin-top: 1.5vh;
}

.hor-download {
  margin-top: auto;
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hor-code-name {
  font-size: 2.5vh;
  color: white;
}

.hor-code {
  width: 12vh;
  height: 12vh;
  margin-top: 2vh;
}

.hor-feature {
  width: 100%;
  /* margin-top: auto;
  margin-bottom: auto; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hor-feature-half {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hor-feature-up {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
}

.hor-feature-down {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
}

.hor-feature-img {
  width: 1.5vh;
  height: 1.5vh;
}

.hor-feature-txt {
  color: rgb(4, 216, 117);
  font-size: 2.2vh;
  font-weight: 400;
  margin-left: 0.8vh;
}

.hor-contact-info {
  width: 100%;
  font-size: 2vmin;
  color: white;
  position: absolute;
  z-index: 2;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 路由前进时的入场/离场动画 */
.forward-enter {
  opacity: 0;
  transform: translateX(100%);
}

.forward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.forward-exit {
  opacity: 1;
  transform: translateX(0);
}

.forward-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 500ms;
}

/* 路由后退时的入场/离场动画 */
.back-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.back-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.back-exit {
  opacity: 1;
  transform: translateX(0);
}

.back-exit-active {
  opacity: 0;
  transform: translate(100%);
  transition: all 500ms;
}
