.ver-app {
  width: 100%;
  position: relative;
}

.ver-app-back {
  width: 100%;
  position: absolute; 
  z-index: 1;
  object-fit: cover;
}

.ver-app-body {
  width: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.ver-head {
  margin-left: 4%;
  margin-top: 8%;
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
}

.ver-head-icon {
  width: 10vw;
  height: 10vw;
}

.ver-head-content {
  color: white;
  font-size: 5.5vw;
  margin-left: 3.5vw;
}

.ver-head-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.ver-head-contract {
  color: white;
  font-size: 2.5vh;
  margin-left: 1vh;
  text-decoration: none;
}

.ver-head-privacy {
  color: white;
  font-size: 2.5vh;
  margin-left: 3vh;
  text-decoration: none;
}

.ver-app-snapshot-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 18%;
}

.ver-app-snapshot-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.ver-app-snapshot-wrapper-2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ver-app-snapshot {
  width: 100%;
  margin-right: -10%;
}

.ver-app-snapshot-2 {
  width: 100%;
  margin-left: -10%;
}

.ver-app-name {
  margin-left: 4%;
  margin-top: 0.1%;
  color: white;
  font-size: 5vw;
}

.ver-app-desc {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 2%;
  color: white;
  font-size: 2.5vw;
  line-height: 4.5vw;
  font-weight: 400;
}

.ver-code-name {
  margin-left: 4%;
  margin-top: 5%;
  font-size: 5vw;
  color: white;
}

.ver-code {
  margin-left: 4%;
  margin-top: 2%;
  width: 20vw;
  height: 20vw;
}

.ver-contact-info {
  width: 100%;
  font-size: 1.5vmin;
  margin-top: 8%;
  color: white;
  text-align: center;
}

.ver-copywrite {
  width: 100%;
  font-size: 1.5vmin;
  margin-top: 8%;
  color: white;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 路由前进时的入场/离场动画 */
.forward-enter {
  opacity: 0;
  transform: translateX(100%);
}

.forward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.forward-exit {
  opacity: 1;
  transform: translateX(0);
}

.forward-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 500ms;
}

/* 路由后退时的入场/离场动画 */
.back-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.back-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.back-exit {
  opacity: 1;
  transform: translateX(0);
}

.back-exit-active {
  opacity: 0;
  transform: translate(100%);
  transition: all 500ms;
}
